<template>
  <div class="content">
    <top-title>
      <span slot="left">安全设置</span>
      <span slot="right">安全设置</span>
    </top-title>
    <el-row class="part1" type="flex" justify="space-between">
      <el-col :span="24" :md="24" class="col-md9">
        <div class="data border">
          <div class="inner layout-flex">
            <div class="col-name">安全手机</div>
            <div class="col-middle">账号保护的一种验证方式，可以用于验证用户身份</div>
            <div class="col-content"  @click="modifyPhone">
              <span>
                {{
                basic.phone != ""
                ? "+86 " +
                basic.phone.substr(0, 3) +
                "****" +
                basic.phone.substr(7, 11)
                : "+86 "
                }}
              </span>
              <i style="margin-left: 10px" class="fa-pencil fa"></i>
            </div>
          </div>
          <div class="inner layout-flex">
            <div class="col-name">联系邮箱</div>
            <div class="col-middle">联系邮箱可以作为账号身份验证的一种辅助校验方式</div>
            <div class="col-content" @click="modifyEmail">
              <span>{{ basic.mail||'待完善' }}</span>
              <i style="margin-left: 10px" class="fa-pencil fa" ></i>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 邮箱验证 -->
    <el-dialog
      :title="emailDialog.title"
      :visible.sync="emailVisible"
      :close-on-click-modal="false"
      width="660px"
      custom-class="email-dialog"
    >
      <template>
        <div class="title layout-flex">
          <i class="iconfont pdxyouxiang"></i> 邮箱验证
        </div>  
           <div class="line layout-flex" v-if="emailState">
              <span class="key">绑定手机</span>
              <span class="value">
                {{
                basic.phone != ""
                ? "+86 " +
                basic.phone.substr(0, 3) +
                "****" +
                basic.phone.substr(7, 11)
                : "+86 "
                }}
              </span>
          </div>
        <div class="line layout-flex" v-if="emailState">
          <span class="key">
            短信验证码
            <span style="color: red">*</span>
          </span>
          <div class="layout-flex">
            <el-input placeholder="请输入验证码" type="text" v-model="emailDialog.code"></el-input>
            <el-button
              type="primary"
              @click="sendCode( basic.phone)"
              style="margin-left:10px"
            >发送验证码</el-button>
          </div>
        </div>
        <div class="line layout-flex" v-else>
          <span class="key">绑定邮箱 <span style="color: red">*</span></span>
          <span class="value">
            <el-form :model="basic">
            <el-form-item style='margin:0' prop='mail' :rules="[
                  {
                    required: true,
                    message: '请输入绑定邮箱',
                    trigger: 'blur',
                  },
                  {
                    validator: ftVal.validateEMail,
                    trigger: 'blur',
                  },
                ]">
              <el-input placeholder="输入邮箱号码" type="text" v-model="basic.mail"></el-input>
            </el-form-item>
            </el-form>  
          </span>
        </div>

        <div style="margin-top: 20px; text-align: center">
          <el-button type="primary" @click="judgeEmail">确定</el-button>
          <el-button @click="emailVisible=false,emailState=true">取消</el-button>
        </div>
      </template>
    </el-dialog>
    <!-- 手机验证 -->
    <el-dialog
      :title="phoneDialog.title"
      :visible.sync="phoneVisible"
      :close-on-click-modal="false"
      width="660px"
      custom-class="phone-dialog"
      :before-close='close'
    >
      <template v-if="phoneDialog.type === 'vecode'">
        <div class="title layout-flex">
          <i class="iconfont pdxshouji"></i> 手机验证
        </div>
        <div class="line">
          <span class="key">绑定手机</span>
          <span class="value">
            {{
            basic.phone != ""
            ? "+86 " +
            basic.phone.substr(0, 3) +
            "****" +
            basic.phone.substr(7, 11)
            : "+86 "
            }}
          </span>
        </div>
        <div class="line layout-flex">
          <span class="key">
            短信验证码
            <span style="color: red">*</span>
          </span>
          <div class="layout-flex">
            <el-input placeholder="6位数字验证码" type="text" v-model="phoneDialog.code"></el-input>
            <el-button type="primary" @click="sendCode(basic.phone)" style="margin-left:10px">{{codeNameHandle('phoneDialog')}}</el-button>
          </div>
        </div>
        <div style="margin-top: 20px; text-align: center">
          <el-button type="primary" @click="judgeCode('phoneDialog')">确定</el-button>
          <el-button @click="close">取消</el-button>
        </div>
      </template>
      <template v-else>
        <div class="title layout-flex">
          <i class="iconfont pdxshouji"></i> 手机验证
        </div>
        <div class="line layout-flex">
          <span class="key">
            新绑定手机
            <span style="color: red">*</span>
          </span>
          <div class="layout-flex value">
            <div class="phone-fornt">+86</div>
            <el-input placeholder="请输入手机号" type="text" v-model="phoneDialog.newPhone"></el-input>
          </div>
        </div>
        <div class="line layout-flex">
          <span class="key">
            短信验证码
            <span style="color: red">*</span>
          </span>
          <div class="layout-flex">

            <el-input placeholder="请输入验证码" type="text" v-model="phoneDialog.code"></el-input>
            <el-button
              type="primary"
              @click="sendCode(phoneDialog.newPhone)"
              style="margin-left:10px"
            >发送验证码</el-button>
          </div>
        </div>
        <div style="margin-top: 20px; text-align: center">
          <el-button
            :disabled="!phoneDialog.code.length || !phoneDialog.newPhone.length"
            type="primary"
            @click="revisePhone"
          >确定</el-button>
          <el-button @click="close">取消</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import * as ftVal from "@/common/fortressValidator.js";
import TopTitle from "@/components/common/top-title";
import { sendSms, checkCode, userInfo } from "@/api/index.js";
import { updatePhone,updateMail } from "@/api/member.js";
export default {
  data () {
    return {
      
      emailState:true,
       ftVal: ftVal,
      emailVerify:"邮箱地址格式不正确",
      emailVisible: false,
      basic: {
        editor: "",
        name: "",
        id: "",
        phone: "",
        mail: "",
        realType: "",
        emailFormat: "",
      },
      phoneVisible: false,
      phoneDialog: {
        title: "身份验证",
        type: "vecode",
        code: "",
        newPhone: "",
        time: 60,
      },
      emailDialog: {
        title: "身份验证",
        code:"",
        type:"",
        time:60
      },
    };
  },
  created () {
    this.basic = JSON.parse(localStorage.getItem("userInfo"));
  },

  components: {
    TopTitle,
  },

  computed: {
    codeNameHandle(){
        return function (name){
        if(this[name].time<60){
         return this[name].time+'秒'
        }else{
          return '发送验证码'
        }
      }
    }
  },

  methods: {
    tableParValueChange(data){
      if(data==''){
         this.emailVerify='邮箱不能为空'
      }else{
        ftVal.validateEMail(data)
      }
     
    },
    emailFormat () {
      if (this.basic.mail != "") {
        const len = this.basic.mail.indexOf("@") - 3;
        const arr = [];
        for (let i = 0; i < len; i++) arr.push("*");
        this.basic.emailFormat =
          this.basic.mail.substr(0, 3) +
          arr.join("") +
          this.basic.mail.substr(
            this.basic.mail.indexOf("@"),
            this.basic.mail.length
          );
      }
      return this.basic.emailFormat;
    },
    modifyPhone () {
      this.phoneVisible = true;
    },
    modifyEmail () {
      this.emailVisible = true;
      this.emailState=true

    },
    judgeEmail(){
      if(this.emailState){
        this.judgeCode('emailDialog')
      }else{
            // 修改email
            updateMail({id: this.basic.id,mail:this.basic.mail}).then(res=>{
              this.$message({
                  type: "success",
                  message: "邮箱修改成功",
                });
                 userInfo().then((resp) => {
                  this.$store.commit("setUserInfo", resp.data);
                });
            })
             this.emailState=true
             this.emailVisible=false
      }
        
    },
    //发送验证码
    sendCode (phone) {
      console.log(phone);
      if (!phone) {
        this.$message({
          message: "请填写手机号码",
          type: "error",
        });
        return;
      } else {
        let reg = /^[1][3-9][0-9]{9}$/;
        if (!reg.test(phone)) {
          this.$message({
            message: "请填写正确的手机号码",
            type: "error",
          });
          return;
        }
      }
      sendSms({ mobile: phone, type: 2 })
        .then((resp) => {
          if (resp.code == 0) {
            this.$message({
              type: "success",
              message: "验证码发送成功, 请注意查收!",
            });
            this.timer = window.setInterval(() => {
              if (--this.time <= 0) {
                this.time = 60;
                clearInterval(this.timer);
              }
            }, 1000);
          } else {
            this.$message({
              message: resp.msg,
              type: "error",
            });
          }
        })
        .catch((e) => {
          this.$message({
            message: e.msg,
            type: "error",
          });
        });
      --this.time;
    },
    //判断校验码
    judgeCode (name) {
      if (!this[name].code) {
        this.$message({
          message: "请填写验证码",
          type: "error",
        });
        return;
      }
      checkCode(this.basic.phone, this[name].code, 2)
        .then((resp) => {
          if (resp.code == 0) {
            this.emailState=false
            this[name].type = "confirm";
            this.phoneDialog.code = "";
            this.time = 60;
          } else {
            this.emailState=true
            this.$message({
              message: resp.msg,
              type: "error",
            });
          }
        })
        .catch((e) => {
          this.emailState=true
          this.$message({
            message: e.msg,
            type: "error",
          });
        });
    },
    //修改手机号
    revisePhone () {
      const reg = /^[1][3-9][0-9]{9}$/;
      if (!reg.test(this.newPhone) && this.newPhone != "") {
        this.$message({
          message: "请输入正确的电话号码",
          type: "error",
        });
      }
      updatePhone({
        newPhone: this.newPhone,
        smsCode: this.code,
        id: this.basic.id,
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "手机号修改成功",
          });
          userInfo().then((resp) => {
            this.$store.commit("setUserInfo", resp.data);
          });
          this.close();
        })
        .catch((e) => {
          this.$message.error(e.msg);
          this.code = "";
        });
    },
    veCodeText () {
      if (this.time >= 60) {
        return "发送验证码";
      }
      if (this.time === 59) {
        return "发送中..."; 
      }
      return `${this.time}秒后重试`;
    },
    close () {
      this.time = 60;
      this.code = "";
      this.newPhone = "";
      this.phoneDialog.type = "vecode";
      this.phoneVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin tit($bottom: 15px) {
  margin-bottom: $bottom;
  font-size: 16px;
  color: #666;
  font-weight: bold;
}
@mixin layout-flex() {
  align-items: flex-start;
  margin-bottom: 20px;
  width: 100%;
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  flex-wrap: wrap;
  align-items: stretch;
}
.content {
  width: 100%;
  .part1 {
    @include layout-flex();
    .col-md9 {
      margin-bottom: 20px;
    }
    .data {
      margin: 0 0 20px 0;
      background: #fff;
      .tit {
        @include tit(0);
        padding: 15px;
        border-bottom: 1px solid #ebeef5;
      }
      .inner {
        padding: 15px;
        .col-name {
          margin-right: 20px;
          color: #666;
          font-size: 16px;
          width: 7%;
        }
        .col-middle {
          margin-right: 20px;
          color: #999;
          font-size: 14px;
          width: 17%;
        }
        .col-content {
          color: #409eff;
          span {
            font-size: 14px;
            // color: #666;
            font-weight: bold;
          }
        }
      }
    }
  }
}
::v-deep .phone-dialog,
::v-deep .email-dialog {
  .el-dialog__body {
    padding: 10px 20px 30px !important;
  }
  .title {
    margin-bottom: 10px;
    width: 120px;
    padding: 6px 0;
    color: #333;
    font-size: 16px;
    justify-content: flex-start;
    i {
      width: 25px;
      height: 25px;
      margin-right: 10px;
      display: inline-block;
      color: #fff;
      font-size: 20px;
      text-align: center;
      line-height: 25px;
      background: #409eff;
      border-radius: 50%;
    }
  }
  .line {
    padding: 5px 0;
    height: 40px;
    font-size: 15px;
    line-height: 40px;
    box-sizing: content-box;
    .key {
      color: #666;
      display: inline-block;
      min-width: 120px;
    }
    .value {
      color: #333;
    }
  }
}
em {
  position: relative;
  &.success {
    color: $success;
    &::before {
      content: '已认证';
    }
  }
  &.error {
    color: $danger;
    &::before {
      content: '未认证';
    }
  }
}

@media screen and (min-width: 992px) {
  .content {
    .part1 {
      .col-md9 {
        margin-bottom: 0;
      }
      .price {
        margin-right: 20px;
      }
    }
  }
}
.v-input {
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  position: relative;
  input {
    width: 100%;
    border-radius: 4;
    border: 1px solid #dcdfe6;
    color: #606266;
    padding: 0 15px;
    height: 100%;
    display: block;
    &:focus {
      border-color: #409eff;
    }
  }
  .clearable {
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    color: #606266;
    right: 12px;
    width: 14px;
    height: 14px;
    margin: auto;
    &:hover {
      color: #000;
      cursor: pointer;
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      transform: scale(1.5);
    }
  }
}
.phone-fornt {
  margin-right: 10px;
  width: 40px;
}
.phone-input {
  display: inline-block;
  vertical-align: top;
}
</style>